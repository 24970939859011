<template>
  <b-modal
    id="modal-add-action"
    no-close-on-backdrop
    title="Добавление нового действия"
    centered
    size="lg"
    cancel-title="Отмена"
    ok-title="Сохранить"
    @show="openShowModal"
    @ok.prevent="saveAction"
  >
    <b-row>
      <b-col md="12">
        <b-form-group label="Названия дейстия">
          <v-select
            v-model="action_type"
            label="title"
            :options="getBpmActionTypes.results"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="action_type">
      <b-col
        v-if="action_type.title.includes('CALL')"
        md="6"
      >
        <b-form-group
          label="Шаблон"
        >
          <v-select
            v-model="new_action.call_template"
            :get-option-label="el => el.title"
            :options="getActionTypeTemplate.results"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="action_type.title.includes('CALL')"
        md="6"
      >
        <b-form-group
          v-if="new_action.call_template"
          label="Локальный номер"
        >
          <b-form-input
            v-model="new_action.call_template.call_ext_number"
            readonly
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col md="12">
        <b-form-textarea
          id="textarea-default"
          v-model="new_action.sms_template"
          placeholder="Шаблон сообщения"
          class="mb-1"
          rows="3"
        />
        <span
          v-if="new_action.sms_template"
          class="float-right"
        >( {{ new_action.sms_template.length }} )</span>
      </b-col>

      <b-col
        v-if="action_type"
        md="12"
      >
        <div v-if="action_type.title.includes('AUTO-SMS')">
          <b-button
            v-for="(item, index) in getSmsTemplateText.results"
            :key="index"
            class="mr-50 mb-50 bg-primary bg-darken-2"
            variant="primary"
            @click="addSmsTemp(item)"
          >
            {{ item.name }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import VSelect from 'vue-select'

import {
  BModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AddEdit',
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    VSelect,
    BFormTextarea,
  },
  props: {
    editItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      new_action: {
        sms_template: '',
        call_template: '',
        is_sendable: true,
      },
      action_type: null,
    }
  },
  computed: {
    ...mapState('actionSettings', ['getBpmActionTypes', 'getActionTypeTemplate', 'getSmsTemplateText']),
  },
  methods: {
    ...mapActions('actionSettings', ['FETCH_SMS_TEMPLATE_TEXT', 'FETCH_BPM_ACTION_TYPE', 'CREATE_BPM_ACTION', 'UPDATE_BPM_ACTION']),

    saveAction() {
      if (this.editItem.id) {
        this.UPDATE_BPM_ACTION({
          id: this.editItem.id,
          data: {
            action_type: this.action_type.id,
            ...this.new_action,
          },
        }).then(() => {
          this.$bvModal.hide('modal-add-action')
          this.$emit('refresh')
          this.$_okToast()
        })
      } else {
        this.CREATE_BPM_ACTION({
          action_type: this.action_type.id,
          ...this.new_action,
        }).then(() => {
          this.$bvModal.hide('modal-add-action')
          this.$emit('refresh')
          this.$_okToast()
        })
      }
    },

    openShowModal() {
      this.FETCH_SMS_TEMPLATE_TEXT()
      this.FETCH_BPM_ACTION_TYPE()

      if (this.editItem.id) {
        this.new_action.sms_template = this.editItem.sms_template
        this.new_action.call_template = this.editItem.call_template
        this.action_type = this.editItem.action_type
      } else {
        this.new_action = {
          sms_template: '',
          call_template: '',
          file: null,
          is_sendable: true,
        }
        this.action_type = null
      }
    },

    addSmsTemp(item) {
      this.new_action.sms_template = `${this.new_action.sms_template} ${item.key}`
    },
  },
}
</script>
