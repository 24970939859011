<template>
  <div>
    <b-button
      v-b-modal:modal-add-action
      size="md"
      variant="primary"
      @click="editItem = {}"
    >
      <feather-icon
        icon="PlusIcon"
      />
      Добавить
    </b-button>

    <b-card class="tablebpmAction table-sta mt-1">
      <b-table
        :fields="columns"
        :items="bpmAction.results"
        empty-text="Нет данных"
        show-empty
        responsive
      >
        <template #cell(call_template)="props">
          <div
            v-if="props.item.call_settings"
            style="width: 300px"
          >
            {{ props.item.call_settings.title[$i18n.locale] }}
          </div>
        </template>

        <template #cell(action_type)="props">
          {{ props.item.action_type ? props.item.action_type.title : '' }}
        </template>

        <template #cell(actions)="props">
          <div class="d-flex align-items-process">
            <b-button
              v-b-modal.modal-add-action
              size="sm"
              variant="warning"
              @click="editItem = props.item"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>

            <b-button
              v-b-modal.modal-delete-action
              class="ml-50"
              size="sm"
              variant="danger"
              @click="deleteBpm(props.item.id)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="params.page_size"
            :options="[5, 10, 20]"
            class="mx-1"
            @change="refresh"
          />
          <span class="ml-1 text-nowrap"> строк( {{ bpmAction.count }} )</span>
        </div>
        <b-pagination
          v-model="params.page"
          :total-rows="bpmAction.count"
          :per-page="params.page_size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <addEdit
        :edit-item="editItem"
        @refresh="refresh"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import areYouSure from '@/mixins/areYouSure'
import addEdit from '../components/addEdit.vue'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BButton,
    BFormSelect,
    addEdit,
  },
  mixins: [areYouSure],
  data() {
    return {
      columns: [
        {
          label: '№',
          key: 'id',
        },
        {
          label: 'ШАБЛОН СООБЩЕНИЯ',
          key: 'sms_template',
        },
        {
          label: 'ШАБЛОН ЗВОНКА',
          key: 'call_template',
        },
        {
          label: 'ЛОКАЛЬНЫЙ НОМЕР',
          key: 'call_ext_number',
        },
        {
          label: 'ТИП ДЕЙСТИЯ',
          key: 'action_type',
        },
        {
          label: 'ДЕЙСТВИЯ',
          key: 'actions',
        },
      ],
      params: {
        page: 1,
        page_size: 10,
      },
      editItem: {},
    }
  },
  computed: {
    ...mapState('actionSettings', ['bpmAction']),
  },
  mounted() {
    this.refresh()
  },
  methods: {
    ...mapActions('actionSettings', ['FETCH_BPM_ACTION', 'DELETE_BPM_ACTION']),

    refresh() {
      this.FETCH_BPM_ACTION(this.params)
    },

    changePage(page) {
      this.params.page = page
      this.refresh()
    },

    deleteBpm(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_BPM_ACTION(id)
            .then(() => {
              this.$_okToast()
              this.refresh()
            })
            .catch(() => {
              this.$_errorToast()
            })
        }
      })
    },
  },
}
</script>
